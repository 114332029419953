<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-email"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'SEND_MAIL' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>
<div class="fx-padding-24">
	@if (sending) {
		<mat-progress-bar
			color="accent"
			mode="indeterminate"
			style="top: -12px"></mat-progress-bar>
	}

	<div [formGroup]="form" class="fx-layout-column-nowrap">
		<mat-form-field class="fx-fill-width">
			<span matTextPrefix>{{ 'TO' | translate }}&nbsp;</span>
			<input matInput formControlName="destAddress" tabindex="-1" />
			<mat-error [hidden]="form.untouched"
				>Adresse email invalide</mat-error
			>
			@if (!isCc) {
				<button mat-button matTextSuffix (click)="isCc = true">
					Cc
				</button>
			}
			@if (!isBcc) {
				<button mat-button matTextSuffix (click)="isBcc = true">
					{{ 'BCC' | translate }}
				</button>
			}
		</mat-form-field>

		@if (isCc) {
			<mat-form-field>
				<span matTextPrefix>Cc&nbsp;</span>
				<input matInput formControlName="cc" tabindex="-1" />
			</mat-form-field>
		}

		@if (isBcc) {
			<mat-form-field>
				<span matTextPrefix>{{ 'BCC' | translate }}&nbsp;</span>
				<input matInput formControlName="bcc" tabindex="-1" />
			</mat-form-field>
		}

		<mat-form-field>
			<span matTextPrefix>{{ 'SUBJECT' | translate }}&nbsp;</span>
			<input matInput formControlName="subject" tabindex="-1" />
		</mat-form-field>

		<div id="editor"></div>
	</div>
</div>
<mat-dialog-actions align="end">
	<button
		(click)="sendMail()"
		[disabled]="form.invalid"
		color="primary"
		mat-raised-button>
		{{ 'SEND' | translate }}
	</button>
</mat-dialog-actions>
